import React from 'react';


export default function Health() {
  return (
    <>
      <h2>Health</h2>
      <h2> I write about health </h2>
    </>
  );
}