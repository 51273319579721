import React from 'react';


export default function Curling() {
  return (
    <>
      <h2>Curing</h2>
      <h2> I write about Curling </h2>
    </>
  );
}