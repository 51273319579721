import React from 'react';


export default function DevOps() {
  return (
    <>
      <h2>DevOps</h2>
      <h2> I write about DevOps </h2>
    </>
  );
}